.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: #005840;
  color: #f8faf2; 
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.game-info, .next-game {
  text-align: left;
  font-size: 2.5em;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  letter-spacing: -0.02em;
  line-height: 1.2; 
  display: block;
  margin-bottom: 0px;
}

.game-info p {
  margin: 0px;
  padding: 0px;
}

div.follow-up {
  margin-top: 1em;
}

.link {
  color: #f8faf2;
  text-decoration: underline;
  font-weight: 900; 
}

.emoji-corner {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: .75em;
  padding: 10px;
  filter: brightness(0) invert(1);
}

a:visited {
  color: #007bff;
  text-decoration: underline;
  font-weight: 900; 
}

@media (max-width: 768px) {
  .game-info, .next-game {
    font-size: 1.75em; 
  }
}

@media (max-width: 768px) {
  .next-7-days {
    flex-direction: column; 
  }

  .game-cell {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Desktop styles */
.next-7-days {
  display: flex;
  flex-direction: row; /* Align horizontally */
  flex-wrap: wrap;
  justify-content: space-around; /* Distribute space around items */
  gap: 10px; /* Add some space between the cells */
}

.game-cell {
  font-weight: 550;
  padding: 5px;
  width: calc(100% / 7 - 10px);
  width: 100px;
  height: 100px;
  border: 1px solid rgb(122, 156, 137);
}

.home {
  color:#005840;
  background-color: rgb(166, 214, 166);
}

.away {
  background-color: offwhite;
}

.no-game {
  background-color: gray;
}

.off {
  background-color: #cccccc;
  color: #666666;
}